import { useState } from "react"
import services from "services"
import message from "utils/message"

const useLoansConsult = () => {
  const [offers, setOffers] = useState()
  const [simulations, setSimulations] = useState([])
  const [limit, setLimit] = useState()
  const [document, setDocument] = useState("")
  const [isConsentVisible, setIsConsentVisible] = useState(false)

  const onSearchAgain = ({ resetForm }) => {
    resetForm()
    setLimit(false)
    setSimulations([])
    setOffers(false)
  }

  const onSubmit = (formData) => {
    setIsConsentVisible(false)

    const { document } = formData
    setDocument(document)
    services.simulations.check(formData).then(handleDataSimulation)
  }

  const handleDataSimulation = (data) => {
    const { code, error, message: apiMessage } = data

    const codes = {
      no_offers: () => setOffers(true),
      error_limit: () => setLimit(true),
      unknown_error: () =>
        message({
          notification: apiMessage || "Serviço indisponível.",
        }),
    }

    if (error && code) {
      return !!codes[code] ? codes[code]() : codes.unknown_error()
    }

    setSimulations(data)
    setLimit(false)
    setOffers(false)
  }

  return {
    onSubmit,
    onSearchAgain,
    offers,
    simulations,
    limit,
    document,
    showConsentText: () => setIsConsentVisible(true),
    isConsentVisible,
  }
}

export default useLoansConsult
