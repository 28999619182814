import * as Yup from "yup"
import validators from "utils/validators"

export const values = {
  document: "",
}

export const validation = Yup.object().shape({
  document: Yup.string()
    .required("Campo obrigatório")
    .test("validDocument", "Documento inválido", (value) =>
      validators.cpf(value)
    ),
})
