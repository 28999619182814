import Icon from "components/Icon"
import If from "components/If"
import Options from "../Options"
import microAcredito from "../../../../images/micro-acredita.png"

import "./Simulations.styl"

const Simulations = ({ hasSimulations, simulations }) => {
  const hasItems = Array.isArray(simulations)

  return (
    <If condition={hasSimulations}>
      {hasItems &&
        simulations?.map((simulation, index) => (
          <If
            condition={simulation.error_code}
            renderElse={
              <>
                <If
                  condition={
                    simulation.product_name === "Microcrédito Acredita"
                  }
                  renderIf={
                    <img
                      src={microAcredito}
                      alt="logo microcredito acredita"
                      className="loans-simulation__img-acredita"
                    />
                  }
                />
                <div className="loans-simulation">
                  <h1 className="loans-simulation__title">
                    <Icon name="circle-check" />
                    Pré aprovado {simulation.product_name}
                  </h1>
                  {Options[simulation.product_name](simulation)}
                </div>
              </>
            }
            renderIf={
              <div
                className="loans-simulation__error"
                v-if="simulation.error_code"
              >
                <Icon name="error" />
                <p className="loans-simulation__error-message">
                  {simulation.error_message}
                </p>
              </div>
            }
            key={index}
          />
        ))}
    </If>
  )
}

export default Simulations
