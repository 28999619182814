import "./Options.styl"

const Option = ({ label, content, ...props }) => (
  <li className="loans-options__item" {...props}>
    <h2 className="loans-options__label">{label}</h2>
    <span className="loans-options__value">{content}</span>
  </li>
)

export default {
  "Cartão iti": ({ available_max_value }) => (
    <ul className="loans-options__list">
      <Option
        label="Limite"
        data-test="itiCard-maxValue"
        content={available_max_value}
      />
    </ul>
  ),
  "Crédito Pessoal iti": ({
    available_max_value,
    installments_number,
    installments_value,
    interest_rate_monthly,
  }) => (
    <ul className="loans-options__list">
      <Option
        label="Valor Máximo"
        data-test="itiPersonalLoans-maxValue"
        content={available_max_value}
      />
      <Option
        label="Parcelas"
        data-test="itiPersonalLoans-installment"
        content={`${installments_number} x ${installments_value}`}
      />
      <Option
        label="Taxa"
        data-test="itiPersonalLoans-fee"
        content={`${interest_rate_monthly} a.m.`}
      />
    </ul>
  ),
  "Crédito Pessoal": ({
    available_max_value,
    installments_number,
    installments_value,
    interest_rate_monthly,
  }) => (
    <ul className="loans-options__list">
      <Option
        label="Valor Máximo"
        data-test="personalLoans-maxValue"
        content={available_max_value}
      />
      <Option
        label="Parcelas"
        data-test="personalLoans-installment"
        content={`${installments_number} x ${installments_value}`}
      />
      <Option
        label="Taxa"
        data-test="personalLoans-fee"
        content={`${interest_rate_monthly} a.m.`}
      />
    </ul>
  ),
  Microcrédito: ({
    available_max_value,
    installments_number,
    installments_value,
    interest_rate_monthly,
  }) => (
    <ul className="loans-options__list">
      <Option
        label="Valor Máximo"
        data-test="microloans-maxValue"
        content={available_max_value}
      />
      <Option
        label="Parcelas"
        data-test="microloans-installment"
        content={`${installments_number} x ${installments_value}`}
      />
      <Option
        label="Taxa"
        data-test="microloans-fee"
        content={`${interest_rate_monthly} a.m.`}
      />
    </ul>
  ),
  "Microcrédito iti": ({
    available_max_value,
    installments_number,
    installments_value,
    interest_rate_monthly,
  }) => (
    <ul className="loans-options__list">
      <Option
        label="Valor Máximo"
        data-test="itiMicroloans-maxValue"
        content={available_max_value}
      />
      <Option
        label="Parcelas"
        data-test="itiMicroloans-installment"
        content={`${installments_number} x ${installments_value}`}
      />
      <Option
        label="Taxa"
        data-test="itiMicroloans-fee"
        content={`${interest_rate_monthly} a.m.`}
      />
    </ul>
  ),
  "Microcrédito Acredita": ({
    available_max_value,
    installments_number,
    installments_value,
    interest_rate_monthly,
  }) => (
    <ul className="loans-options__list">
      <Option
        label="Valor Máximo"
        content={available_max_value}
      />
      <Option
        label="Parcelas"
        content={`${installments_number} x ${installments_value}`}
      />
      <Option
        label="Taxa"
        content={`${interest_rate_monthly} a.m.`}
      />
    </ul>
  ),
}
