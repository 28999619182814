import Icon from "components/Icon"
import Title from "components/Typography/Title"
import Text from "components/Typography/Text"
import If from "components/If"

import "./LoansResult.styl"

const LoansResult = ({ limit, offers, document }) => (
  <>
    <If condition={limit}>
      <div className="loans-result">
        <Icon className="loans-result__icon" name="error-result" />
        <Title className="loans-result__title" variant="small">
          Você atingiu o limite de consultas para hoje
        </Title>
        <Text className="loans-result__text">
          Por segurança, pedimos que espere até amanhã para fazer novas
          consultas
        </Text>
      </div>
    </If>
    <If condition={offers}>
      <div className="loans-result">
        <Icon className="loans-result__icon" name="error-result" />
        <Title className="loans-result__title" variant="small">
          Não foram encontradas ofertas para o documento {document}
        </Title>
      </div>
    </If>
  </>
)

export default LoansResult
