import Button from "components/Button"
import Can from "components/Can"
import Card from "components/Card"
import If from "components/If"
import LoansResult from "./components/LoansResult"
import Simulations from "./components/Simulations"

import Form from "components/Forms/Form"
import Input from "components/Forms/Input"
import maskers from "utils/maskers"

import { values, validation } from "./form"

import useLoansConsult from "./hooks"

import "./LoansConsult.styl"

const LoansConsult = () => {
  const {
    onSubmit,
    onSearchAgain,
    document,
    offers,
    simulations,
    limit,
    isConsentVisible,
    showConsentText,
  } = useLoansConsult()

  const isForm = !limit
  const hasSimulations = offers || simulations.length

  return (
    <Can
      business={["loans", "microloans", "itiMicroloans", "itiLoans", "itiCard"]}
      do={[
        "simulation",
        "simulation",
        "simulation",
        "simulation",
        "simulation",
      ]}
    >
      <div className="loans-consult">
        <Card
          className="loans-consult__card"
          title="Consulta de ofertas pré-aprovadas"
        >
          <Form
            className="form-loans-consult"
            onSubmit={onSubmit}
            defaultValues={values}
            schema={validation}
            manually={true}
          >
            {({ form, state: { isDisabled }, reset }) => (
              <>
                <If condition={isForm}>
                  <If condition={!offers}>
                    <Input
                      className="form-loans-consult__input"
                      name="document"
                      placeholder="Digite um CPF"
                      mask={maskers.cpf}
                      disabled={hasSimulations}
                      form={form}
                      onClick={showConsentText}
                    />
                  </If>
                  <If condition={!hasSimulations}>
                    <Button
                      className="form-loans-consult__submit"
                      type="submit"
                      disabled={isDisabled}
                    >
                      Consultar ofertas
                    </Button>
                  </If>
                </If>
                <Simulations
                  hasSimulations={hasSimulations}
                  simulations={simulations}
                />
                <LoansResult
                  document={document}
                  limit={limit}
                  offers={offers}
                />
                <If condition={hasSimulations}>
                  <Button onClick={() => onSearchAgain({ resetForm: reset })}>
                    Fazer uma nova consulta
                  </Button>
                </If>
                <If condition={isConsentVisible}>
                  <p className="form-loans-consult__text">
                    O cliente autoriza a consulta de informações a seu respeito
                    mantidas no sistema de informações de crédito (SCR)
                    organizado pelo Banco Central do Brasil.
                  </p>
                </If>
              </>
            )}
          </Form>
        </Card>
      </div>
    </Can>
  )
}

export default LoansConsult
